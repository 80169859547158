.label {
  position: relative;
  display: block;
  background-color: white;
}

.select {
  position: absolute;
  color: transparent;
  top: 0;
  /* text-align: center;
  text-align-last: center; */

  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
  font: inherit;
  outline: none;
  background-color: #fff;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
  opacity: 0;
  padding-right: 10px;
  box-sizing: content-box;
  /* position: relative; */
  z-index: 1;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: none;
}
