.activity-feed, .activity-feed--reverse {
  padding-top: 0.5rem;
}

.activity-dot {
  content: '';
  width: 0.571rem;
  height: 0.571rem;
  left: -0.3214rem;
}

.activity-content {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
}

.activity-container:last-child {
  border-left-width: 0;
  border-right-width: 0;
}

.activity-feed--reverse div:last-child .activity-dot,
.activity-feed div:first-child .activity-dot {
  background-color: #627D98;
}

.activity-feed--reverse div:last-child .activity-dot,
.activity-feed div:first-child .activity-dot {
  right: -0.263rem;
}

.activity-feed--reverse div:last-child .activity-dot:before,
.activity-feed div:first-child .activity-dot:before {
  content: '';
  background-color: #627D98;
  width: 0.571rem;
  height: 0.571rem;
  position: absolute;
  left: 0.1rem;
  border-radius: 1000px;
  z-index: 12;
}

.activity-feed--reverse div:last-child .activity-dot:after,
.activity-feed div:first-child .activity-dot:after {
  content: '';
  background-color: #D9E2EC;
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: -0.635rem;
  top: -0.7rem;
  border-radius: 1000px;
  z-index: 11;
}
