.react-datepicker-wrapper {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}

#range-input .react-datepicker__input-container {
  height: 100%;
  display: flex;
}

.react-datepicker__header {
  background: #fff;
  border-bottom: 0px solid #fff;
  padding-top: 0;
}

.react-datepicker__week, .react-datepicker__day-names {
  text-align: auto;
  display: grid;
  grid-template-columns: repeat(7, 2.449rem);
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1.429rem;
  margin-left: 1.429rem;
}

.react-datepicker__day--today {
  font-weight: 400;
  position: relative;
}

.react-datepicker__week > div {
  align-self: center;
}

.react-datepicker__day, .react-datepicker__day-name {
  margin: auto;
  border-radius: 72rem;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #102A43;
}

.react-datepicker__day::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-datepicker__day-name {
  color: #627D98;
  font-weight: 500;
}

.react-datepicker__month {
  margin: 0.714rem 0 0 0;
  display: grid;
  row-gap: 1.429rem;
}

.react-datepicker__day--selected {
  position: relative;
  content: none;
}

.react-datepicker__day--selected > *,
.react-datepicker__day--range-end > *,
.react-datepicker__day--range-start > * {
  z-index: 1000;
}

.react-datepicker__day:hover {
  background-color: transparent;
}

.react-datepicker__day--in-range:after,
.react-datepicker__day--in-selecting-range:after,
.react-datepicker__day:hover:after {
  position: absolute;
  background-color: #DCEEFB;
  width: 2.142rem;
  height: 2.142rem;
  content: '';
  border-radius: 72rem;
  z-index: 10;
}

.react-datepicker__month--selecting-range .react-datepicker__day--range-end .react-datepicker__day:hover:after > *,
.react-datepicker__month--selecting-range .react-datepicker__day--range-start .react-datepicker__day:hover:after > * {
  color: #102A43 !important;
  z-index: 100;
}

.react-datepicker__day--selected:hover:after {
  position: absolute;
  background-color: #216ba5;
  opacity: 1;
  width: 2.142rem;
  height: 2.142rem;
  content: '';
  border-radius: 72rem;
  z-index: 10;
}

.react-datepicker__day--disabled:hover:after {
  opacity: 0;
}

.react-datepicker-popper {
  z-index: 30;
}

.react-datepicker-popper[data-placement^="bottom"] .datepicker-arrow {
  display: block;
  top: -0.5rem;
}

.react-datepicker-popper[data-placement^="top"] .datepicker-arrow {
  display: block;
  bottom: -0.5rem;
}

.react-datepicker-popper .datepicker-arrow {
  display: none;
  left: 9.429rem;
}
.react-datepicker-popper .datepicker-arrow:before {
  border-radius: 0;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__day:hover > * {
  background-color: transparent;
  color: #186FAF;
  font-weight: 500;
}

.react-datepicker__day--in-range > *,
.react-datepicker__day--in-selecting-range > *,
.react-datepicker__day:hover > * {
  z-index: 100;
}

.react-datepicker__day--selected:after,
.react-datepicker__day--range-end:after,
.react-datepicker__day--range-start:after {
  position: absolute;
  background-color: #216ba5;
  width: 2.142rem;
  height: 2.142rem;
  content: '';
  border-radius: 72rem;
  z-index: 10;
}

.react-datepicker__week > * {
  outline: none !important;
}

.react-datepicker__day--disabled, .react-datepicker__day--outside-month, .react-datepicker__day--disabled:hover > * {
  color: #829AB1;
  font-weight: 400;
}

.react-datepicker__day--selected > *,
.react-datepicker__day--selected:hover > *,
.react-datepicker__month--selecting-range .react-datepicker__day--range-end > *,
.react-datepicker__month--selecting-range .react-datepicker__day--range-start > *,
.react-datepicker__day--range-end .react-datepicker__day--in-range,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  font-weight: 500;
  color: #fff;
  z-index: 100;
}


.react-datepicker__day--today:after {
  position: absolute;
  content: '';
  border-radius: 72rem;
  width: 2.142rem;
  height: 2.142rem;
  border: 1px solid #216ba5;
}
